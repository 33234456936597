<template>
  <div class="about-page">
    <div class="block-banner about-banner">
      <div class="bg--black">
        <div class="container">
          <h2 class="block-banner__title"  v-scroll-reveal="{
            distance: '80px',
            origin: 'left'
          }">{{lang.menu.about_title}}</h2>
        </div>
      </div>
    </div>
    <div class="section-about">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-3">
            <div class="navigation">
              <ul class="nav navigation__ul">
                <li>
                  <router-link exact class="active" :to="{ name: 'AboutPage', params: { type: 'quienes-somos' } }">{{lang.about.tabs.tab1}}</router-link>
                </li>
                <li>
                  <router-link exact :to="{ name: 'AboutPage', params: { type: 'socios' } }">{{lang.about.tabs.tab2}}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-md-8 col-md-offset-1">
            <template v-if="type === 'quienes-somos'">
              <div class="block-text">
                <h3>{{lang.about.tabs.tab1}}</h3>
                <p>{{lang.about.tab1.text1}}</p>
                <div class="d-block">
                  <img src="@/assets/bg_about.png" alt="">
                </div>
                <template v-if="language === 'es'">
                  <p><b>{{lang.about.tab1.text7}}</b></p>
                  <div v-html="lang.about.tab1.text8"></div>
                  <h4>"{{lang.about.tab1.text9}}"</h4>
                </template>
                <template v-else>
                  <p><b>{{lang.about.tab1.text2}}</b></p>
                  <div v-html="`${lang.about.tab1.text3}`"></div>
                  <h4>"{{lang.about.tab1.text4}}"</h4>
                </template>
              </div>
              <div id="digit" class="digit-section">
                <div class="container-fuild">
                  <div class="digit">
                    <div class="digit__wrapper" id="achievementsId">
                      <div class="digit__item" v-scroll-reveal="{ delay: 400 }">
                        <div class="digit__inner">
                          <img src="@/assets/icons/icon-professional.svg" />
                          <span class="number">+<i-count-up
                            id="number1"
                            class="digits"
                            :delay="-1"
                            :end-val="25"
                            @ready="onReady"
                          /></span>
                          <span class="name">{{lang.home.digit.block1}}</span>
                        </div>
                      </div>
                      <div class="digit__item" v-scroll-reveal="{ delay: 450 }">
                        <div class="digit__inner">
                          <img src="@/assets/icons/icon-country.svg" />
                          <span class="number">+<i-count-up
                            id="number1"
                            class="digits"
                            :delay="-1"
                            :end-val="5"
                            @ready="onReady"
                          /></span>
                          <span class="name">{{lang.home.digit.block2}}</span>
                        </div>
                      </div>
                      <div class="digit__item" v-scroll-reveal="{ delay: 550 }">
                        <div class="digit__inner">
                          <img src="@/assets/icons/icon-client.svg" />
                          <span class="number">+<i-count-up
                            id="number1"
                            class="digits"
                            :delay="-1"
                            :end-val="12"
                            @ready="onReady"
                          /></span>
                          <span class="name">{{lang.home.digit.block4}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="block-text">
                <h3>{{lang.about.tabs.tab2}}</h3>
                <span v-html="lang.about.tab2.text1"></span>
                <div class="partner">
                  <div class="partner__item">
                    <div class="partner__img">
                      <img src="@/assets/edgard_schweig.jpg" alt="Edgard Schweig - Pacific" />
                    </div>
                    <div class="partner__description">
                      <h4>Edgard Schweig</h4>
                      <p>{{lang.about.tab2.text2}}</p>
                      <p>{{lang.about.tab2.text3}}</p>
                      <ul class="nav">
                        <li>{{lang.about.tab2.text4}}</li>
                        <li>{{lang.about.tab2.text5}}</li>
                        <li>{{lang.about.tab2.text6}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="partner__item">
                    <div class="partner__img">
                      <img src="@/assets/jose-antonio-tord.jpg" alt="Jose Antonio Tord - Pacific" />
                    </div>
                    <div class="partner__description">
                      <h4>Jose Antonio Tord</h4>
                      <p v-html="lang.about.tab2.text7"></p>
                      <p>{{lang.about.tab2.text8}}</p>
                      <ul class="nav">
                        <li>{{lang.about.tab2.text9}}</li>
                        <li>{{lang.about.tab2.text10}}</li>
                        <li>{{lang.about.tab2.text11}}</li>
                        <li>{{lang.about.tab2.text12}}</li>
                        <li>{{lang.about.tab2.text13}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="partner__item">
                    <div class="partner__img">
                      <img src="@/assets/bernardo-infante.jpg" alt="Bernardo Infante - Pacific" />
                    </div>
                    <div class="partner__description">
                      <h4>Bernardo Infante</h4>
                      <p>{{lang.about.tab2.text14}}</p>
                      <p>{{lang.about.tab2.text15}}</p>
                      <ul class="nav">
                        <li>{{lang.about.tab2.text16}}</li>
                        <li>{{lang.about.tab2.text17}}</li>
                        <li>{{lang.about.tab2.text18}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ICountUp from "vue-countup-v2";

export default {
  name: "AboutPage",
  components: {
    ICountUp,
  },
  props: {
    type: {
      type: String,
      default: 'quienes-somos'
    }
  },
  data: () => ({
    activeGraph: false,
  }),
  methods: {
    offset(el) {
      const rect = el.getBoundingClientRect();
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
    onReady(instance) {
      const achievementsId = document.getElementById("achievementsId");
      window.addEventListener("scroll", () => {
        document.documentElement.scrollTop >
        this.offset(achievementsId).top - 350
          ? instance.start()
          : null;
      });
    },
  }
}
</script>